import React, { useEffect, useState, useCallback } from 'react';
import { bool, func, string } from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { loadListingCarousel } from '../../containers/SearchClaimPage/SearchClaimPage.duck';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { useConfiguration } from '../../context/configurationContext';
import css from './MoreMindersCarousel.module.css';
import classNames from 'classnames';
import BookingDateRangeFilter from '../../containers/SearchPage/BookingDateRangeFilter/BookingDateRangeFilter';
import { initialValues } from '../../containers/SearchPage/SearchPage.shared';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import SelectMultipleFilter from '../../containers/SearchPage/SelectMultipleFilter/SelectMultipleFilter';
import CustomDynamicLogo from '../CustomDynamicLogo/CustomDynamicLogo';
import { validate as uuidValidate } from 'uuid';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import ListingsCarouselComponent from './ListingsCarouselComponent';

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

const getListingIds = (listingIdsKey, publicData) => {
  if (!publicData[listingIdsKey]) {
    return null;
  }

  const listingIdsString = publicData[listingIdsKey];
  const idArray =
    typeof listingIdsString === 'string'
      ? listingIdsString.split(/[,;]/).map(id => id.trim())
      : listingIdsString;

  const validUUIDs = idArray.filter(uuidValidate);

  return { ids: validUUIDs };
};

const hotelIcon = title => {
  return <CustomDynamicLogo title={title} />;
};

const MoreMindersCarousel = props => {
  const {
    name,
    className,
    listings,
    queryListingsCarouselInProgress,
    queryListingsCarouselError,
    loadListingCarousel,
    hasSeparator,
    listingsCarouselPagination,
    location,
    intl,
    title,
    showFilters,
    showDynamicLogo,
    publicData,
    listingIdsKey,
    noFoundMessage,
    hasAuth,
    isAuthenticated,
  } = props;
  const config = useConfiguration();
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState('');
  const [weekSchedule, setWeekSchedule] = useState('');
  const [childcareFilters, setChildcareFilters] = useState('');
  const [languageFilters, setLanguageFilters] = useState('');

  const classNameRoot = classNames(css.root, className);

  const fetchListings = useCallback(async () => {
    setIsLoading(true);
    const extractValuesFromEnumOptions = (array, keyToFind, valueKey) => {
      const paramToFind = `${keyToFind}`;
      const item = array.find(item => item.key === paramToFind);
      if (item && Array.isArray(item.enumOptions)) {
        return item.enumOptions.map(option => option[valueKey]);
      }
      return [];
    };

    const isCategoryGroupSelected = 'group_childcare';
    const getCategories = extractValuesFromEnumOptions(
      config?.listing?.listingFields,
      isCategoryGroupSelected,
      'option'
    );
    const filteredCategories = getCategories.filter(item => item !== 'creche');

    const minderListingIds = getListingIds(listingIdsKey, publicData);

    const categoryParams = !minderListingIds
      ? { filterId: 'group_childcare', listingTypes: filteredCategories }
      : {};

    const params = {
      ...categoryParams,
      page: currentPage,
    };

    const searchParams = {
      ...dateRange,
      ...weekSchedule,
      ...childcareFilters,
      ...languageFilters,
      ...minderListingIds,
    };
    await loadListingCarousel(params, searchParams, config);
    setIsLoading(false);
  }, [
    loadListingCarousel,
    config,
    currentPage,
    dateRange,
    weekSchedule,
    childcareFilters,
    languageFilters,
  ]);

  useEffect(() => {
    fetchListings();
  }, [fetchListings]);

  const handleListingCardClick = event => {
    event.stopPropagation();
    if (hasAuth && !isAuthenticated) {
      const state = {
        listingInfo: {
          listingLink: `${location.pathname}`,
          categoryType: publicData?.listingType,
        },
      };
      history.replace({ pathname: '/signup', state }); // Replace the current entry with the signup page
      return;
    }
  };

  const handleDateChange = updatedValues => {
    setDateRange(updatedValues);
  };
  const handleWeekScheduleChange = updatedValues => {
    setWeekSchedule(updatedValues);
  };
  const handleChildcareFilterChange = updatedValues => {
    setChildcareFilters(updatedValues);
  };
  const handleLanguageChange = updatedValues => {
    setLanguageFilters(updatedValues);
  };

  if (queryListingsCarouselError) {
    return <div>Error loading listings</div>;
  }

  const renderDateFilter = () => {
    const dateConfig = config?.search?.defaultFilters.find(field => field.key === 'dates');
    const { dateRangeMode } = dateConfig;
    const isNightlyMode = dateRangeMode === 'night';
    const fromSearchParams = initialValues(['timezone']);
    const timezone = fromSearchParams.timezone || defaultTimeZone();
    const initialValuesWithTimezone = { ...dateRange, timezone: timezone };

    return (
      <BookingDateRangeFilter
        id="dateFilter"
        label={intl.formatMessage({ id: 'FilterComponent.datesLabel' })}
        queryParamNames={['dates']}
        initialValues={initialValuesWithTimezone}
        onSubmit={handleDateChange}
        minimumNights={isNightlyMode ? 1 : 0}
      />
    );
  };
  const renderAvailabilityFilter = () => {
    const availabilityConfig = config?.listing?.listingFields.find(
      field => field.key === 'weekSchedule'
    );
    const { enumOptions, filterConfig, schemaType, showConfig, key } = availabilityConfig;
    const { searchMode } = filterConfig;
    const { label } = showConfig;
    const labelCustom = label && label.split('--')[0].trim();
    const initialValues = { ...weekSchedule };
    return (
      <SelectMultipleFilter
        id={key}
        label={labelCustom}
        name={key}
        queryParamNames={[`pub_${key}`]}
        initialValues={initialValues}
        onSubmit={handleWeekScheduleChange}
        options={enumOptions}
        schemaType={schemaType}
        searchMode={searchMode}
        showAsPopup={true}
        contentPlacementOffset={0}
      />
    );
  };
  const renderChildcareFilter = () => {
    const availabilityConfig = config?.listing?.listingFields.find(
      field => field.key === 'jobFilters_childcare'
    );
    const {
      enumOptions: enumOptionsRaw,
      filterConfig,
      schemaType,
      showConfig,
      key,
    } = availabilityConfig;
    const { searchMode } = filterConfig;
    const { label } = showConfig;
    const labelCustom = label && label.split('--')[0].trim();
    const initialValues = { ...childcareFilters };
    const name = key.replace(/\s+/g, '-').toLowerCase();
    const requiredOptions = [
      'sport_activities',
      'newborn',
      'puzzle_board_games',
      'arts_crafts',
      'first_aid',
      'dublin_zoo',
      'pool_time',
    ];

    const enumOptions = enumOptionsRaw.filter(option => requiredOptions.includes(option.option));

    return (
      <SelectMultipleFilter
        id={key}
        label={labelCustom}
        name={name}
        queryParamNames={[`pub_${key}`]}
        initialValues={initialValues}
        onSubmit={handleChildcareFilterChange}
        options={enumOptions}
        schemaType={schemaType}
        searchMode={searchMode}
        showAsPopup={true}
        contentPlacementOffset={-14}
      />
    );
  };

  const renderLanguageFilter = () => {
    const availabilityConfig = config?.listing?.listingFields.find(
      field => field.key === 'jobLanguages'
    );
    const { enumOptions, filterConfig, schemaType, showConfig, key } = availabilityConfig;
    const { searchMode } = filterConfig;
    const { label } = showConfig;
    const labelCustom = label && label.split('--')[0].trim();
    const initialValues = { ...languageFilters };
    const name = key.replace(/\s+/g, '-').toLowerCase();

    return (
      <SelectMultipleFilter
        id={key}
        label={labelCustom}
        name={name}
        queryParamNames={[`pub_${key}`]}
        initialValues={initialValues}
        onSubmit={handleLanguageChange}
        options={enumOptions}
        schemaType={schemaType}
        searchMode={searchMode}
        showAsPopup={true}
        contentPlacementOffset={-14}
      />
    );
  };

  const hotelIconElement = showDynamicLogo ? (
    <div className={css.imageLogo}> {hotelIcon(title)}</div>
  ) : null;

  return (
    <div name={name} id={name} className={classNameRoot}>
      {hasSeparator && <span className={css.separator}></span>}
      {(title || showFilters) && (
        <div className={css.heading}>
          {title && (
            <div className={css.titleWrapper}>
              <h3 className={css.title}>
                <span className={css.textBlue}>{title}</span>
              </h3>
              {hotelIconElement}
            </div>
          )}
          {showFilters && (
            <div className={css.filtersContainer}>
              <span>{renderDateFilter()}</span>
              <span>{renderAvailabilityFilter()}</span>
              <span>{renderChildcareFilter()}</span>
              {/*            <span>{renderLanguageFilter()}</span> */}
            </div>
          )}
        </div>
      )}
      <div className={css.carouselContainer}>
        {isLoading ? (
          <div>Loading...</div>
        ) : listings && listings.length > 0 ? (
          <ListingsCarouselComponent
            listings={listings}
            isLoading={isLoading}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            listingsCarouselPagination={listingsCarouselPagination}
            handleListingCardClick={handleListingCardClick}
            queryListingsCarouselInProgress={queryListingsCarouselInProgress}
          />
        ) : (
          <div>{noFoundMessage}</div>
        )}
      </div>
    </div>
  );
};

MoreMindersCarousel.defaultProps = {
  hasSeparator: true,
  listingsCarouselPagination: null,
  showFilters: false,
  showDynamicLogo: false,
  listingIdsKey: null,
  noFoundMessage: null,
  hasAuth: false,
  isAuthenticated: false,
  name: null,
};

MoreMindersCarousel.propTypes = {
  queryListingsCarouselInProgress: bool.isRequired,
  queryListingsCarouselError: bool,
  loadListingCarousel: func.isRequired,
  intl: intlShape.isRequired,
  hasSeparator: bool,
  listingsCarouselPagination: propTypes.pagination,
  showFilters: bool,
  showDynamicLogo: bool,
  listingIdsKey: string,
  noFoundMessage: string,
  hasAuth: bool,
  isAuthenticated: bool,
  name: string.isRequired,
};

const mapStateToProps = state => {
  const {
    listingsCarousel,
    queryListingsCarouselInProgress,
    queryListingsCarouselError,
    listingsCarouselPagination,
  } = state.SearchClaimPage;

  const { isAuthenticated } = state.auth;

  const listings = listingsCarousel;
  return {
    listings,
    queryListingsCarouselInProgress,
    queryListingsCarouselError,
    listingsCarouselPagination,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  loadListingCarousel: (params, search, config) =>
    dispatch(loadListingCarousel(params, search, config)),
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MoreMindersCarousel)
);
