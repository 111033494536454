import React, { useRef, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import ListingCard from '../ListingCard/ListingCard';
import Pagination from './Pagination';
import css from './MoreMindersCarousel.module.css';

const ListingsCarouselComponent = ({
  listings,
  isLoading,
  currentPage,
  setCurrentPage,
  listingsCarouselPagination,
  handleListingCardClick,
  queryListingsCarouselInProgress,
}) => {
  const carouselRef = useRef(null);
  const [temporaryListings, setTemporaryListings] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalDots, setTotalDots] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [disableScrollHandler, setDisableScrollHandler] = useState(false);

  useEffect(() => {
    if (!queryListingsCarouselInProgress) {
      setTemporaryListings(listings);
      setCurrentIndex(0);
      if (carouselRef.current) {
        carouselRef.current.scrollTo({ left: 0, behavior: 'auto' }); // Reset scroll position
      }
    }
  }, [listings, queryListingsCarouselInProgress]);

  const calculateTotalDots = useCallback(() => {
    if (carouselRef.current && temporaryListings.length > 0) {
      const card = carouselRef.current.firstChild;
      const cardWidth = card ? card.offsetWidth : 150;
      const cardMargin = 10;
      const fullCardWidth = cardWidth + cardMargin;
      const visibleWidth = carouselRef.current.offsetWidth;
      const totalWidth = temporaryListings.length * fullCardWidth;
      const dots = Math.ceil((totalWidth - visibleWidth) / fullCardWidth) + 1;
      setTotalDots(dots);
    }
  }, [temporaryListings]);

  useEffect(() => {
    calculateTotalDots();
    const handleResize = () => {
      calculateTotalDots();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [calculateTotalDots]);

  const scrollCarousel = direction => {
    if (carouselRef.current) {
      const carousel = carouselRef.current;
      const card = carousel.firstChild;
      const cardWidth = card ? card.offsetWidth : 150;
      const cardMargin = 10;
      const fullCardWidth = cardWidth + cardMargin;
      const maxScrollLeft = carousel.scrollWidth - carousel.clientWidth;
      const scrollRangePerDot = maxScrollLeft / (totalDots - 1);

      setIsScrolling(true);
      setDisableScrollHandler(true);

      if (direction === 'right') {
        if (currentIndex >= totalDots - 1) {
          carousel.scrollTo({ left: 0, behavior: 'instant' });
          setCurrentIndex(0);
        } else {
          carousel.scrollBy({ left: scrollRangePerDot, behavior: 'smooth' });
          setCurrentIndex(prevIndex => prevIndex + 1);
        }
      } else if (direction === 'left') {
        if (currentIndex <= 0) {
          carousel.scrollTo({ left: maxScrollLeft, behavior: 'instant' });
          setCurrentIndex(totalDots - 1);
        } else {
          carousel.scrollBy({ left: -scrollRangePerDot, behavior: 'smooth' });
          setCurrentIndex(prevIndex => prevIndex - 1);
        }
      }

      setTimeout(() => {
        setDisableScrollHandler(false);
      }, 500); // Adjust the timeout duration as needed
    }
  };

  const handleScroll = () => {
    if (disableScrollHandler) {
      return;
    }

    if (isScrolling) {
      setIsScrolling(false);
      return;
    }

    if (carouselRef.current) {
      const carousel = carouselRef.current;
      const scrollLeft = carousel.scrollLeft;
      const totalScrollWidth = carousel.scrollWidth - carousel.clientWidth;
      const scrollRangePerDot = totalScrollWidth / (totalDots - 1);
      const newIndex = Math.round(scrollLeft / scrollRangePerDot);

      requestAnimationFrame(() => {
        setCurrentIndex(newIndex);
      });
    }
  };

  const handleDotClick = index => {
    if (carouselRef.current) {
      const card = carouselRef.current.firstChild;
      const cardWidth = card ? card.offsetWidth : 150;
      const cardMargin = 10;
      const fullCardWidth = cardWidth + cardMargin;
      const newScrollLeft = index * fullCardWidth;

      setIsScrolling(true);
      setDisableScrollHandler(true);
      carouselRef.current.scrollTo({ left: newScrollLeft, behavior: 'instant' });
      setCurrentIndex(index);

      setTimeout(() => {
        setDisableScrollHandler(false);
      }, 500); // Adjust the timeout duration as needed
    }
  };

  return (
    <>
      <div className={css.carouselControls}>
        <button
          className={css.carouselButton}
          onClick={() => {
            scrollCarousel('left');
          }}
        >
          &#9664;
        </button>
        <button
          className={css.carouselButton}
          onClick={() => {
            scrollCarousel('right');
          }}
        >
          &#9654;
        </button>
      </div>
      <div
        className={`${css.carousel} ${isLoading ? css.loading : ''}`}
        ref={carouselRef}
        onScroll={handleScroll}
      >
        {temporaryListings.map(listing => (
          <div key={listing.id.uuid} onClick={handleListingCardClick}>
            <ListingCard key={listing.id.uuid} listing={listing} className={css.listingCard} />
          </div>
        ))}
      </div>
      <div className={css.carouselIndicators}>
        {Array.from({ length: totalDots }).map((_, index) => (
          <div
            key={index}
            className={`${css.carouselIndicator} ${index === currentIndex ? css.active : ''}`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
      <Pagination
        totalPages={listingsCarouselPagination.totalPages}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      />
    </>
  );
};

ListingsCarouselComponent.propTypes = {
  listings: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  listingsCarouselPagination: PropTypes.object.isRequired,
  handleListingCardClick: PropTypes.func.isRequired,
  queryListingsCarouselInProgress: PropTypes.bool.isRequired,
};

export default ListingsCarouselComponent;
