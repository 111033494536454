import React from 'react';
import PropTypes from 'prop-types';
import css from './Pagination.module.css';

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  // Return null if there is only one page
  if (totalPages <= 1) {
    return null;
  }

  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className={css.paginationContainer}>
      <button
        className={`${css.pageButtonAction} ${currentPage === 1 ? css.disabled : ''}`}
        onClick={handlePrevPage}
        disabled={currentPage === 1}
      >
        Prev
      </button>
      {pages.map(page => (
        <button
          key={page}
          className={`${css.pageButton} ${page === currentPage ? css.active : ''}`}
          onClick={() => onPageChange(page)}
        >
          {page}
        </button>
      ))}
      <button
        className={`${css.pageButtonAction} ${currentPage === totalPages ? css.disabled : ''}`}
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

Pagination.propTypes = {
  totalPages: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
